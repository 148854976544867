import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Blog Pages
import BlogPage1 from "./pages/BlogPage1";
import BlogPage2 from "./pages/BlogPage2";

// Generell
import About from "./pages/About";
import NoPage from "./pages/NoPage";
import Impressum from "./pages/Impressum";
import Home from "./pages/Home";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/junghund-an-welpen-gewoehnen" element={<BlogPage1 />} />
        <Route path="/ab-wann-ist-ein-welpe-ein-junghund" element={<BlogPage2 />} />
        
        <Route path="/about" element={<About />} />
        <Route path="/impressum" element={<Impressum />} />
        <Route path="/datenschutz" element={<Impressum/>} />
        <Route path="*" element={<NoPage />} />
      </Routes>
    </BrowserRouter>
  ); 
}

export default App;

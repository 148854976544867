
import Article from "../components/Article";
import Footer from "../components/footer";
import Header from "../components/header";


function BlogPage({title, author_name, author_image, author_date, author_description, articleContent}) {

    return (
    <div>
      <Header></Header>
      <body className="h-fit">
        <Article 
          title={title}
          author_name={author_name}
          author_image={author_image}
          author_date={author_date}
          author_description={author_description}
          articleContent={articleContent}></Article>
      </body>
      <Footer></Footer>
    </div>
  );
}

export default BlogPage;
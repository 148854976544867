import Footer from "../components/footer";
import Header from "../components/header";


function NoPage(props) {

    return (
    <div>
      <Header></Header>
      <body className="h-fit">
        <h1>Error 404 - Page not found</h1>
      </body>
      <Footer></Footer>
    </div>
  );
}

export default NoPage;
import RelatedArticles from "../components/RelatedArticles";
import Footer from "../components/footer";
import Header from "../components/header";

function Home(props) {

    return (
      <div>
      <Header></Header>
      <body className="h-fit">
      <main class="pt-8 pb-16 lg:pt-16 lg:pb-24 bg-white dark:bg-gray-900 antialiased">

      <div class="flex justify-between px-4 mx-auto max-w-screen-xl ">
        <div class="mx-auto w-full max-w-2xl format format-sm sm:format-base lg:format-lg format-blue dark:format-invert">
          <header class="mb-4 lg:mb-6 not-format">
            <h1 class="mb-4 text-3xl font-extrabold leading-tight text-gray-900 lg:mb-6 lg:text-4xl dark:text-white">Willkommen bei pawunwind!</h1>
          </header>

          <figure><img class="rounded" src="/imgs/chewy-Invez0M-FVg-unsplash.jpg" alt="Pawunwind community">
              </img><figcaption></figcaption>
          </figure>

          <p>Willkommen bei PawUnwind, Deinem Begleiter auf der Reise zu einem harmonischen Zusammenleben mit Deinem vierbeinigen Freund.
          Unser Blog ist mehr als nur eine Informationsquelle; es ist eine Gemeinschaft von Hundefreunden, die sich der Förderung des Wohlergehens und der Gesundheit unserer treuen Begleiter widmet.</p>

          <p>Durchstöbere unsere vielfältigen Blogbeiträge. Jeder Artikel ist sorgfältig recherchiert und verfasst, um Dir präzise und hilfreiche Ratschläge zu bieten.</p>


        <p>Bei PawUnwind glauben wir, dass jedes Haustier ein glückliches und erfülltes Leben verdient. Mit Empathie und Expertise streben wir danach, jeden Hundebesitzer zu befähigen, die bestmögliche Pflege und Liebe für seinen pelzigen Freund zu bieten.</p>

        <p>Herzlich willkommen im Kreis der Hundeliebhaber bei PawUnwind. Wir freuen uns darauf, diese wunderbare Reise mit Dir zu teilen.</p>

        </div>
      </div>
      </main>

      <RelatedArticles></RelatedArticles>
      </body>
      <Footer></Footer>
    </div>
  );
}

export default Home;
import Newsletter from "./Newsletter";
import RelatedArticles from "./RelatedArticles";
import Author from "./Author";


function Article({title, author_name, author_image, author_date, author_description, articleContent}) {

    return (<>
<main class="pt-8 pb-16 lg:pt-16 lg:pb-24 bg-white dark:bg-gray-900 antialiased">

  <div class="flex justify-between px-4 mx-auto max-w-screen-xl ">
    <div class="mx-auto w-full max-w-2xl format format-sm sm:format-base lg:format-lg format-blue dark:format-invert">
      <header class="mb-4 lg:mb-6 not-format">
        <h1 class="mb-4 text-3xl font-extrabold leading-tight text-gray-900 lg:mb-6 lg:text-4xl dark:text-white">{title}</h1>
        <Author name={author_name} description={author_description} date={author_date} img={author_image}></Author>
      </header>

      {articleContent}

    </div>
  </div>
</main>

<RelatedArticles></RelatedArticles>
{//<Newsletter></Newsletter>
}

    </>);
}


export default Article;
import Footer from "../components/footer";
import Header from "../components/header";


function About(props) {

    return (
    <div>
      <Header></Header>
      <body className="h-fit">
        <main class="pt-8 pb-16 lg:pt-16 lg:pb-24 bg-white dark:bg-gray-900 antialiased">

            <div class="flex justify-between px-4 mx-auto max-w-screen-xl ">
            <div class="mx-auto w-full max-w-2xl format format-sm sm:format-base lg:format-lg format-blue dark:format-invert">
                <header class="mb-4 lg:mb-6 not-format">
                    <h1 class="mb-4 text-3xl font-extrabold leading-tight text-gray-900 lg:mb-6 lg:text-4xl dark:text-white">Über uns</h1>
                </header>

                <figure class="justify-center"><img class="" src="/imgs/aboutus.png" alt="">
                    </img><figcaption>Nele Monique</figcaption>
                </figure>

                <h2 className="mb-4 text-2xl font-extrabold leading-tight text-gray-900 lg:mb-6 lg:text-2xl dark:text-white">Hallo liebe Tierfreunde und Hundebegeisterte!</h2>

                <p class="lead">
                    Mein Name ist Nele Monique. Ich bin eine leidenschaftliche Designerin und eine echte Tierliebhaberin.
                    Mit pawunwind möchte ich und meine Freunde eine Brücke zwischen diesen beiden Welten schlagen. 
                    Hier verschmelzen Ästhetik, Design und vor allem die unendliche Liebe zu unseren vierbeinigen Freunden.
                </p>

                <p>
                    Schon seit meiner Kindheit haben Hunde und andere Tiere mein Herz erobert. Sie sind nicht nur treue Begleiter, sondern auch Quellen der Inspiration und Freude.
                    Mein Ziel mit pawunwind ist es, diese Freude und Inspiration mit euch zu teilen. Sei es durch wunderschön gestaltete Inhalte, hilfreiche Tipps rund um den Hund oder Geschichten, die das Herz berühren.
                </p>

                <img src="/imgs/kojirou-sasaki-rdLQVeroHQ0-unsplash.jpg" alt=""></img>

                <p>
                    Bei pawunwind geht es nicht nur um Hundecontent, sondern um eine Gemeinschaft. Es ist ein Ort, an dem wir uns alle zusammentun können, um unsere Erfahrungen, Geschichten und Momente mit unseren treuen Begleitern zu teilen.
                </p>

                <p>
                    Ich hoffe, dass ihr euch hier genauso zu Hause fühlt wie ich. Lasst uns gemeinsam die Welt durch die Augen unserer Hunde sehen und ihre unendliche Liebe und Freude mit der Welt teilen
                </p>
            

                <h2 className="mb-4 text-2xl font-extrabold leading-tight text-gray-900 lg:mb-6 lg:text-2xl dark:text-white">Danke Schön!</h2>

                <p>
                    Dir ist bestimmt schon aufgefallen, dass wir bei pawunwind wunderschöne Fotos verwenden, um unsere Beiträge zum Leben zu erwecken.
                    Diese beeindruckenden Aufnahmen sind nicht nur simple Hintergrundbilder; sie sind das Herzstück, welches unsere Geschichten und Erlebnisse hervorhebt.
                    Ein großes Dankeschön geht an die talentierten Stock Image Künstler, die ihre Kunst mit uns teilen und es uns ermöglichen, unsere Inhalte visuell so ansprechend zu gestalten. Ihre Hingabe und ihr Können verdienen Anerkennung.
                    Daher möchten wir euch herzlich einladen, ihre Portfolios zu besuchen und euch von ihrer beeindruckenden Arbeit inspirieren zu lassen.
                </p>

                <p>Unsere geschätzten Bildkünstler:</p>

                <ul>
                    <li><a href="https://unsplash.com/de/@reskp?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Jametlene Reskp</a> auf <a href="https://unsplash.com/de/fotos/hGKXqee3Zxw?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a></li>
                    <li><a href="https://unsplash.com/de/@berkaygumustekin?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Berkay Gumustekin</a> auf <a href="https://unsplash.com/de/fotos/ngqyo2AYYnE?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a></li>
                    <li><a href="https://unsplash.com/de/@karlkiwi90?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Karl Anderson</a> auf <a href="https://unsplash.com/de/fotos/jKDFcXwk5Cw?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a></li>
                    <li><a href="https://unsplash.com/de/@alvannee?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Alvan Nee</a> auf <a href="https://unsplash.com/de/fotos/1VgfQdCuX-4?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a></li>
                    <li><a href="https://unsplash.com/de/@ilovethatforyou?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Destiny Wiens</a> auf <a href="https://unsplash.com/de/fotos/RIyGkwus3Po?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a></li>
                    <li><a href="https://unsplash.com/de/@zlucerophoto?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Zach Lucero</a> auf <a href="https://unsplash.com/de/fotos/WjzZOaYvo_s?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a></li>
                    <li><a href="https://unsplash.com/de/@barkernotbaker?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">James Barker</a> auf <a href="https://unsplash.com/de/fotos/v3-zcCWMjgM?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></li>
                    <li><a href="https://unsplash.com/de/@chewy?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Chewy</a> auf <a href="https://unsplash.com/de/fotos/Invez0M-FVg?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></li>
                    <li><a href="https://unsplash.com/de/@xangriffin?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Xan Griffin</a> auf <a href="https://unsplash.com/de/fotos/UCFgM_AojFg?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></li>
                    <li><a href="https://unsplash.com/de/@wadeaustinellis?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Wade Austin Ellis</a> auf <a href="https://unsplash.com/de/fotos/FtuJIuBbUhI?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></li>
                    <li><a href="https://unsplash.com/de/@theflouffy?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">FLOUFFY</a> auf <a href="https://unsplash.com/de/fotos/VW2HGWgtIfw?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></li>
                </ul>


            </div>
            </div>
        </main>
      </body>
      <Footer></Footer>
    </div>
  );
}

export default About;
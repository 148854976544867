


function RelatedArticles (props) {
    return <>
        <aside aria-label="Related articles" class="py-8 lg:py-24 bg-gray-50 dark:bg-gray-800">
  <div class="px-4 mx-auto max-w-screen-xl">
      <h2 class="mb-8 text-2xl font-bold text-gray-900 dark:text-white">Diese Artikel könnten Dir auch gefallen:</h2>
      <div class="grid gap-12 sm:grid-cols-2 lg:grid-cols-4">
          <article class="max-w-xs">
              <a href="/junghund-an-welpen-gewoehnen">
                  <img src="/imgs/jametlene-reskp-hGKXqee3Zxw-unsplash.jpg" class="mb-5 rounded-lg" alt="Image 1">
                    </img>
              </a>
              <h2 class="mb-2 text-xl font-bold leading-tight text-gray-900 dark:text-white">
                  <a href="/junghund-an-welpen-gewoehnen">Junghund an Welpen gewöhnen: Ein umfassender Leitfaden</a>
              </h2>
              <p class="mb-4 text-gray-500 dark:text-gray-400">Leitfaden mit Schritt für Schritt Anleitung, zur Eingliederung eines neuen Welpen in eine Familie mit einem bestehenden Junghund.</p>
              <a href="/junghund-an-welpen-gewoehnen" class="inline-flex items-center font-medium underline underline-offset-4 text-primary-600 dark:text-primary-500 hover:no-underline">
                  Lesezeit 3 Minuten
              </a>
          </article>
          <article class="max-w-xs">
              <a href="/ab-wann-ist-ein-welpe-ein-junghund">
                  <img src="/imgs/wade-austin-ellis-FtuJIuBbUhI-unsplash.jpg" class="mb-5 rounded-lg" alt="Image 2">
             
                  </img> </a>
              <h2 class="mb-2 text-xl font-bold leading-tight text-gray-900 dark:text-white">
                  <a href="/ab-wann-ist-ein-welpe-ein-junghund">Ab wann ist ein Welpe ein Junghund: Ein umfassender Leitfaden</a>
              </h2>
              <p class="mb-4  text-gray-500 dark:text-gray-400">Dieser Artikel beleuchtet die Übergangsphase vom Welpen zum Junghund und bietet praktische Tipps für Hundebesitzer.</p>
              <a href="/ab-wann-ist-ein-welpe-ein-junghund" class="inline-flex items-center font-medium underline underline-offset-4 text-primary-600 dark:text-primary-500 hover:no-underline">
                Lesezeit: 7 Minuten
              </a>
          </article>
      </div>
  </div>
</aside>
    
    </>;
}


export default RelatedArticles;
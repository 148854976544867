


function Author({name, description, date, img}) {
    return <>

        <address class="flex items-center mb-6 not-italic">
            <div class="inline-flex items-center mr-3 text-sm text-gray-900 dark:text-white">
                <img class="mr-4 w-16 h-16 rounded-full" src={img} alt={name}>
                </img><div>
                    <a href="#" rel="author" class="text-xl font-bold text-gray-900 dark:text-white">{name}</a>
                    <p class="text-base text-gray-500 dark:text-gray-400">{description}</p>
                    <p class="text-base text-gray-500 dark:text-gray-400"><time pubdate datetime="2022-02-08" title="February 8th, 2022">{date}</time></p>
                </div>
            </div>
        </address>
    </>
}


export default Author;